import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../graphql/Auth";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";

const LoginRegister = () => {
  const [passwordInputType, setPasswordInputType] = useState("password");
  const { Login } = useAuth();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const [error, setError] = React.useState(false);
  let { pathname } = useLocation();
  const [loginAsUser] = useMutation(LOGIN_USER);

  const isLoggedIn = localStorage.getItem("user") ? true : false;
  const router = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      router("/");
    }
  }, [isLoggedIn, router]);
  const handleLoginSubmit = async (e) => {
    setError(false);
    try {
      const response = await loginAsUser({
        variables: {
          password: e.password,
          email: e.email,
        },
      });
      if (response.data.loginAsUser.otpRequired) {
        return router("/otp?email=" + e.email);
      }
      if (response.data.loginAsUser.user.role === "SELLER") {
        return (window.location.href =
          "https://airomnishopvander.dev-bt.xyz/dashboard?accessToken=" +
          response.data.loginAsUser.token);
      }
      localStorage.setItem("token", response.data.loginAsUser.token);
      Login(response.data.loginAsUser);
      router("/");
    } catch (error) {
      toast.error({
        message: "Login Failed",
        description: error.message,
      });
      setError(error);
      console.log(error.message);
    }
  };
  return (
    <Fragment>
      <SEO titleTemplate="Login" description="Login page ofAiromnishop  " />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Login Register",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="login-register-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ms-auto me-auto">
                <div className="login-register-wrapper">
                  <Tab.Container defaultActiveKey="login">
                    <Nav variant="pills" className="login-register-tab-list">
                      <Nav.Item>
                        <Nav.Link eventKey="login">
                          <h4>Login</h4>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="login">
                        <div className="login-form-container">
                          <div className="login-register-form">
                            <Formik
                              initialValues={{
                                email: "",
                                password: "",
                                rememberMe: false,
                              }}
                              onSubmit={handleLoginSubmit}
                              validationSchema={validationSchema}
                            >
                              {({ isSubmitting }) => (
                                <Form>
                                  {error && (
                                    <div class="alert alert-danger alert-dismissible fade show">
                                      {error.message}
                                    </div>
                                  )}
                                  <div className="form-group mb-3">
                                    <Field
                                      name="email"
                                      type="text"
                                      className="form-control"
                                      placeholder="Username or email address"
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="form-group  form-forgot">
                                    <div style={{ position: "relative" }}>
                                      <Field
                                        name="password"
                                        type={passwordInputType}
                                        className="form-control"
                                        placeholder="Password..."
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "8px",
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {passwordInputType === "password" && (
                                          <i
                                            onClick={() =>
                                              setPasswordInputType("text")
                                            }
                                            className="fa fa-eye"
                                          />
                                        )}
                                        {passwordInputType === "text" && (
                                          <i
                                            onClick={() =>
                                              setPasswordInputType("password")
                                            }
                                            className="fa fa-eye-slash"
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="button-box">
                                    <div className="login-toggle-btn">
                                      <input type="checkbox" />
                                      <label className="ml-10">
                                        Remember me
                                      </label>
                                      <Link
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/forgetpassword"
                                        }
                                      >
                                        Forgot Password?
                                      </Link>
                                    </div>
                                    <button type="submit " className="w-100">
                                      {isSubmitting ? "Loading..." : "Login"}
                                    </button>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                        width: "100%",
                                      }}
                                    >
                                      <Link
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/register/user"
                                        }
                                      >
                                        if you dont have account Register
                                      </Link>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default LoginRegister;
